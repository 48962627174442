import React from 'react';
import { Checkbox, Radio } from 'react-bootstrap';
import MaskedInput from 'react-maskedinput';
import Geosuggest from '@ubilabs/react-geosuggest';
import Select from 'react-select';
import AutocompleteCitiesField from 'Rescript/styleguide/forms/AutocompleteCitiesField/AutocompleteCitiesField.res.js';

export const renderFormField = ({
  input,
  labelStyle,
  label,
  placeholder,
  displayLabel = true,
  className,
  type,
  disabled,
  onSuggestSelect,
  location,
  radius,
  initialValue,
  options,
  meta: { touched, error },
  onlySearchCities,
  withProducts,
  showSelectedValue,
  onSelected,
  classNamePrefix,
}) => (
  <div>
    {(type === 'text' || type === 'url') && (
      <div>
        {displayLabel && <label>{label}</label>}
        <input {...input} className={className} placeholder={placeholder} type={type} disabled={disabled} />
        {touched && error && (
          <span className={labelStyle} style={{ color: 'red', fontSize: '13px' }}>
            {error}
          </span>
        )}
      </div>
    )}
    {type === 'textarea' && (
      <div>
        {displayLabel && <label>{label}</label>}
        <textarea
          {...input}
          className={className}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
        />
        {touched && error && (
          <span className={labelStyle} style={{ color: 'red', fontSize: '13px' }}>
            {error}
          </span>
        )}
      </div>
    )}
    {type === 'number' && (
      <div>
        {displayLabel && <label>{label}</label>}
        <input
          {...input}
          className={className}
          placeholder={placeholder}
          size="10"
          type={type}
          disabled={disabled}
        />
        {touched && error && (
          <span className={labelStyle} style={{ color: 'red', fontSize: '13px' }}>
            {error}
          </span>
        )}
      </div>
    )}
    {type === 'phone' && (
      <div>
        {displayLabel && <label>{label}</label>}
        <MaskedInput
          {...input}
          mask="111-111-1111"
          className={className}
          placeholder={placeholder}
          disabled={disabled}
        />
        {touched && error && (
          <span className={labelStyle} style={{ color: 'red', fontSize: '13px' }}>
            {error}
          </span>
        )}
      </div>
    )}
    {type === 'year' && (
      <div>
        {displayLabel && <label>{label}</label>}
        <MaskedInput
          {...input}
          mask="1111"
          className={className}
          placeholder={placeholder}
          disabled={disabled}
        />
        {touched && error && (
          <span className={labelStyle} style={{ color: 'red', fontSize: '13px' }}>
            {error}
          </span>
        )}
      </div>
    )}
    {type === 'password' && (
      <div>
        {displayLabel && <label>{label}</label>}
        <input
          {...input}
          className={className}
          placeholder={placeholder}
          size="10"
          type={type}
          disabled={disabled}
        />
        {touched && error && (
          <span className={labelStyle} style={{ color: 'red', fontSize: '13px' }}>
            {error}
          </span>
        )}
      </div>
    )}
    {type === 'radio' && (
      <Radio {...input} inline>
        {label}
      </Radio>
    )}
    {type === 'checkbox' && (
      <Checkbox {...input} disabled={disabled} className={className} inline>
        {label}
      </Checkbox>
    )}
    {type === 'search' && <input type="text" className={className} placeholder={placeholder} {...input} />}
    {type === 'geoSuggest' && (
      <div>
        {displayLabel && <label>{label}</label>}
        <Geosuggest
          {...input}
          onSuggestSelect={onSuggestSelect}
          location={location}
          radius={radius}
          initialValue={initialValue}
          placeholder={placeholder}
          disabled={disabled}
        />
        {touched && error && (
          <span className={labelStyle} style={{ color: 'red', fontSize: '13px' }}>
            {error}
          </span>
        )}
      </div>
    )}
    {type === 'select' && (
      <div>
        {displayLabel && <label>{label}</label>}
        <Select
          {...input}
          className={className}
          classNamePrefix={classNamePrefix}
          options={options}
          placeholder={placeholder}
          disabled={disabled}
          onChange={input.onChange}
          onBlur={() => input.onBlur(input.value)}
          value={options.find(({ value }) => value === input.value)}
        />
        {touched && error && (
          <span className={labelStyle} style={{ color: 'red', fontSize: '13px' }}>
            {error}
          </span>
        )}
      </div>
    )}
    {type === 'autocomplete' && (
      <div>
        {displayLabel && <label>{label}</label>}
        <AutocompleteCitiesField
          {...input}
          initialValue={initialValue}
          placeholder={placeholder}
          onlySearchCities={onlySearchCities}
          withProducts={withProducts}
          showSelectedValue={showSelectedValue}
          onSelected={onSelected}
        />
        {touched && error && (
          <span className={labelStyle} style={{ color: 'red', fontSize: '13px' }}>
            {error}
          </span>
        )}
      </div>
    )}
  </div>
);
